import { Redirect } from "react-router-dom";
import React, { lazy } from "react";
import { renderRoutes } from "react-router-config";

// Views
import Space from "views/user/Space/Space";
import Bundles from "views/user/Bundles/Bundles";
import Profile from "views/user/Profile/Profile";
import OrganisationSettings from "views/user/OrganisationSettings/OrganisationSettings";
import AddOrganisation from "views/user/OrganisationSettings/AddOrganisation";
import OrganisationsList from "views/user/OrganisationSettings/OrganisationsList";
import Orders from "views/user/Orders/Orders";
import Terminologies from "views/user/Terminologies/Terminologies";
//import Analytics from 'views/user/Analytics';
//import Formats from 'views/user/Formats';
import Suppliers from "views/user/Suppliers/Suppliers";
import Customers from "views/user/Customers/Customers";
import Categories from "views/user/Categories/Categories";
import TermsAndConditions from "views/TermsAndConditions.jsx";
import PrivacyPolicy from "views/PrivacyPolicy.jsx";
import Chat from "views/user/Chat/Chat";
import InventoryReports from "views/user/SkuBreakdown/InventoryReports";

import ErrorLayout from "layouts/Error";
import AuthLayout from "layouts/AuthLayout";
import UserLayout from "./layouts/UserLayout";
import OverviewView from "./views/user/Overview";

import { generatePath } from "react-router";
import Invoices from "views/user/Invoices";
import Presentation from "views/user/Presentation";

import AdminLayout from "layouts/AdminLayout/AdminLayout";
import AdminDemo from "views/admin/AdminDemo";
import AdminAuthLayout from "./layouts/AdminAuthLayout";
import { adminAuthRoutes, adminRoutes } from "./actions/routesAdmin";
import WarehouseForm from "views/admin/Warehouse/WarehouseForm";
import Wallet from './views/user/Wallet';

// Views_Admin
const AdminOrders = lazy(() => import("views/admin/Orders/Orders"));
const ParcelMaterial = lazy(() =>
  import(
    "views/admin/Orders/OrdersDetail/components/Shipping/OutboundShipping/ParcelMaterial.jsx"
  )
);
const AdminBatches = lazy(() =>
  import("views/admin/Batches/BatchesSummary.jsx")
);
const ChooseTrolley = lazy(() =>
  import("views/admin/Batches/ChooseTrolley.js")
);
const AdminSKU = lazy(() => import("views/admin/SKU/SKU"));
const AdminBundles = lazy(() => import("views/admin/Bundles/Bundles"));

const SummaryEdit = lazy(() =>
  import("views/admin/SKU/SKUDetail/components/Summary/SummaryEdit.jsx")
);
const AdminInvoices = lazy(() =>
  import("views/admin/Accounting/Invoices/Invoice.jsx")
);

const AdminShippingDetail = lazy(() =>
  import("views/admin/Accounting/Shipping/ShippingDetail")
);
const StandardEdit = lazy(() =>
  import("views/admin/Accounting/Shipping/Standard/StandardEdit.js")
);

const AdminInventory = lazy(() =>
  import("views/admin/Warehouse/Inventory/Inventory.jsx")
);
const AdminSettings = lazy(() =>
  import("views/admin/Settings/SettingsDetail.jsx")
);
const AdminSuppliers = lazy(() =>
  import("views/admin/Warehouse/Suppliers/Suppliers.jsx")
);
const AdminUsers = lazy(() => import("views/admin/Settings/Users/Users.js"));
const AdminOrganisations = lazy(() =>
  import("views/admin/AdminOrganisations/AdminOrgDetail.jsx")
);
const Duties = lazy(() => import("views/admin/Accounting/Other"));
const Warehouse = lazy(() => import("views/admin/Warehouse"));
const Support = lazy(() => import("views/admin/Support/Support"));
//const Zones = lazy(() => import('views/admin/Warehouse/Zones'))

export const reverseUserRoute = (name, id) => {
  const matchedRoute = userRoutes.filter((route) => route.name === name)[0];
  let finalPath;
  const uid = window.localStorage.getItem("organisation-uid");

  if (id) {
    finalPath = generatePath(matchedRoute.path, { id, uid });
  } else {
    if (uid) {
      finalPath = generatePath(matchedRoute.path, { uid });
    } else {
      finalPath = matchedRoute.path;
    }
  }

  return finalPath;
};

const Root = ({ route }) => <>{renderRoutes(route.routes)}</>;

export const userRoutes = [
  // Profile by naked url and ID'd url. In future, the ID'd url can point to other profiles and accounts
  {
    name: "ProfileForID",
    path: "/user/:uid/profile/:id",
    exact: true,
    component: Profile,
  },
  {
    name: "Profile",
    path: "/user/:uid/profile/",
    exact: true,
    component: Profile,
  },
  {
    name: "OrganisationSettings",
    path: "/user/:uid/organisation_settings/",
    exact: true,
    component: OrganisationSettings,
  },
  {
    name: "AddOrganisation",
    path: "/user/:uid/add_organisation",
    exact: true,
    component: AddOrganisation,
  },
  {
    name: "OrganisationsList",
    path: "/user/:uid/organisations",
    exact: true,
    component: OrganisationsList,
  },
  {
    name: "Overview",
    path: "/user/:uid/overview",
    exact: true,
    component: OverviewView,
  },

  // Space
  {
    name: "Space",
    path: "/user/:uid/space/products",
    exact: false,
    component: Space,
  },
  {
    name: "Bundles",
    path: "/user/:uid/space/bundles",
    exact: false,
    component: Bundles,
  },
  {
    name: "Categories",
    path: "/user/:uid/space/categories",
    exact: false,
    component: Categories,
  },
  // Orders
  {
    name: "Orders",
    path: "/user/:uid/orders/",
    component: Orders,
  },
  {
    // Symbolic
    name: "Orders:Summary",
    path: "/user/:uid/orders/summary/",
  },
  {
    name: "Orders:Terminologies",
    path: "/user/:uid/terminologies/",
    component: Terminologies,
  },
  // Manage
  {
    name: "Suppliers:Index",
    path: "/user/:uid/manage/suppliers/",
    component: Suppliers,
  },
  {
    name: "Customers",
    path: "/user/:uid/manage/customers/",
    component: Customers,
  },
  {
    path: "/user/:uid/manage/team/",
    component: lazy(() => import("views/NotImplemented")),
  },
  // Chat
  {
    name: "Chat:Index",
    path: "/user/:uid/chat",
    exact: true,
    component: Chat,
  },
  {
    name: "Chat:Detail",
    path: "/user/:uid/chat/:id",
    exact: true,
    component: Chat,
  },

  // Invoices
  {
    path: "/user/:uid/invoices",
    component: Invoices,
    permissions: ["owner"],
  },

  {
    path: "/user/:uid/wallet",
    component: Wallet,
    permissions: ["owner"],
  },

  // Analytics
  {
    path: "/user/:uid/reports/inventory/",
    component: InventoryReports,
  },

  // Dev Test Area
  {
    path: "/test",
    component: lazy(() => import("views/user/Testing")),
  },
  {
    path: "/presentation",
    component: Presentation,
  },
  {
    component: () => <Redirect to="/errors/error-404" />,
  },
];

// Friendly reminder that to edit the links in the navbar, you should look in components/NavBar/navigationConfig.js
// This file is only for the physical routing of pages in the virtual dom
export const routes = [
  {
    component: Root,
    routes: [
      {
        path: "/",
        exact: true,
        component: () => <Redirect to={reverseUserRoute("Overview")} />,
      },
      {
        path: "/auth",
        component: AuthLayout,
        routes: [
          {
            path: "/auth/login",
            exact: true,
            component: lazy(() => import("layouts/AuthLayout")),
            //component: AuthLayout
          },
          {
            path: "/auth/register",
            exact: true,
            component: AuthLayout,
          },
          {
            path: "/auth/privacy_policy",
            exact: true,
            component: PrivacyPolicy,
          },
          {
            path: "/auth/terms_and_conditions",
            exact: true,
            component: TermsAndConditions,
          },
          {
            path: "/auth/reset_password",
            exact: true,
            component: AuthLayout,
          },

          {
            path: "/auth/magic_signin_link",
            exact: true,
            component: AuthLayout,
          },
          {
            path: "/auth/invitation/accept",
            exact: true,
            component: AuthLayout,
          },
          {
            path: "/auth/organisations",
            exact: true,
            component: AuthLayout,
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },
      {
        path: "/errors",
        component: ErrorLayout,
        routes: [
          {
            path: "/errors/error-401",
            exact: true,
            component: lazy(() => import("views/Error401")),
          },
          {
            path: "/errors/error-404",
            exact: true,
            component: lazy(() => import("views/Error404")),
          },
          {
            path: "/errors/error-500",
            exact: true,
            component: lazy(() => import("views/Error500")),
          },
          {
            component: () => <Redirect to="/errors/error-404" />,
          },
        ],
      },

      {
        path: "/admin/auth",
        component: AdminAuthLayout,
        routes: adminAuthRoutes,
      },

      {
        path: "/admin",
        component: AdminLayout,
        routes: [
          {
            path: "/admin",
            exact: true,
            component: () => <Redirect to="/admin/manage/orders" />,
          },
          {
            path: "/admin/",
            exact: true,
            component: () => <Redirect to="/admin/manage/orders" />,
          },
          {
            path: "/admin/test",
            exact: true,
            component: AdminDemo,
          },

          {
            path: "/admin/manage/orders",
            exact: true,
            component: AdminOrders,
          },

          {
            path: "/admin/manage/orders/:id/shipment/parcel",
            exact: true,
            component: AdminOrders,
          },

          {
            // name: 'Orders:detail',
            path: "/admin/manage/orders/:id",
            // exact: true,
            component: AdminOrders,
          },

          // Admin/Batches
          {
            path: "/admin/manage/batches",
            exact: true,
            component: AdminBatches,
          },
          {
            path: "/admin/manage/batches/:id/choose-trolley",
            exact: true,
            component: AdminBatches,
          },
          {
            path: "/admin/manage/batches/:id",
            exact: true,
            component: AdminBatches,
          },
          {
            path: `/admin/manage/batches/:id/detail`,
            exact: true,
            component: AdminBatches,
          },

          // Admin/Sku
          {
            path: "/admin/products/sku",
            //exact: true,
            component: AdminSKU,
          },

          {
            path: "/admin/bundles",
            component: AdminBundles,
          },

          {
            path: "/admin/products/sku/detail",
            //exact: true,
            component: AdminSKU,
          },
          {
            path:
              "/admin/products/sku/SKUDetail/components/Summary/SummaryEdit",
            exact: false,
            component: SummaryEdit,
          },
          // Admin/Accounting
          // Invoice

          {
            path: "/admin/accounting/invoices/:invoice_id/details",
            exact: true,
            component: AdminInvoices,
          },
          {
            path: "/admin/accounting/invoices/drafts/:invoice_id/details",
            exact: true,
            component: AdminInvoices,
          },
          {
            path: "/admin/accounting/invoices",
            //exact: true,
            component: AdminInvoices,
          },

          // Shipping
          /* {
            path: '/admin/warehouses/:wid/shipping',
            exact: true,
            component: AdminShipping
          },
          */

          {
            path: "/admin/warehouses/add",
            component: WarehouseForm,
          },
          {
            path: "/admin/warehouses/:id/edit",
            component: WarehouseForm,
          },

          {
            path: "/admin/warehouses/:wid/shipping/:id/:tab",
            component: AdminShippingDetail,
          },
          {
            path: "/admin/warehouses/:wid/shipping/:id/:tab/add",
            component: AdminShippingDetail,
          },
          {
            path: "/admin/warehouses/:wid/shipping/:id/:tab/:subId/edit",
            component: AdminShippingDetail,
          },
          {
            path: "/admin/warehouses/:wid/shipping/standard/Edit",
            exact: false,
            component: StandardEdit,
          },

          // Inventory
          {
            path: "/admin/warehouses/inventory",
            exact: true,
            component: AdminInventory,
          },
          {
            path: "/admin/warehouses/inventory/add/:type",
            exact: true,
            component: AdminInventory,
          },
          {
            path: "/admin/warehouses/inventory/:id/detail/:tab",
            //exact: true,
            component: AdminInventory,
          },
          {
            path: "/admin/warehouses/:tab",
            component: Warehouse,
          },

          /*{
            path: '/admin/warehouses/locations',
            component: Warehouse
          },*/

          //Supplier
          {
            path: "/admin/warehouses/suppliers",
            exact: true,
            component: AdminSuppliers,
          },
          {
            path: "/admin/warehouses/suppliers/add",
            exact: true,
            component: AdminSuppliers,
          },
          {
            path: "/admin/warehouses/suppliers/:id/edit",
            exact: true,
            component: AdminSuppliers,
          },

          //Other - Duties
          {
            path: "/admin/accounting/others",
            component: Duties,
          },
          /*{
            path: '/admin/warehouses/zones',
            component: Zones
          },*/

          // Settings
          {
            path: "/admin/settings",
            exact: true,
            component: AdminSettings,
          },
          {
            path: "/admin/settings/:tab",
            exact: false,
            component: AdminSettings,
          },

          {
            path: "/admin/settings/users",
            component: AdminUsers,
          },

          {
            path: "/admin/organisations/:tab",
            component: AdminOrganisations,
          },

          //Support
          {
            path: "/admin/support/tickets",
            //exact: true,
            component: Support,
          },
          {
            path: "/admin/support/global-comments",
            //exact: true,
            component: Support,
          },
          {
            path: "/admin/errors/error-404",
            exact: true,
            component: lazy(() => import("views/Error404")),
          },
          {
            component: () => <Redirect to="/admin/errors/error-404" />,
          },
        ],
      },

      {
        path: "/user",
        component: UserLayout,
        routes: userRoutes,
      },

      {
        path: "/undefined",
        component: ErrorLayout,
        routes: [
          {
            path: "/undefined/error",
            exact: true,
            component: lazy(() => import("views/Error404")),
          },
        ],
      },

      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
];
