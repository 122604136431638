import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";

import InputIcon from "@material-ui/icons/Input";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {Button, Typography, Toolbar, withStyles} from "@material-ui/core";
import {CSVLink} from "react-csv";
import BasicDrawer from "components/Drawers/BasicDrawer";
import SpaceFilterDrawerContents from "./SpaceFilterDrawerContents";
import SpaceSearch from "components/Search/SpaceSearch";
import {UID} from 'AppConstants';

class SpaceMenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterPanelOpen: false,
      csvExportData: "",
    };
    this.generateCSVExport = this.generateCSVExport.bind(this);
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      filterPanelOpen: open,
    });
  };

  rejectKeys(item, keys, items) {
    let exportedItems = Object.assign({}, item);
    keys.forEach((key) => delete exportedItems[key]);
    if(exportedItems.image) exportedItems["image"] = exportedItems.image.url;
    return exportedItems;
  }

  generateCSVExport() {
    this.setState({
      loading: true
    });
    let exportItems = this.props.items.map((item, index) =>
      this.rejectKeys(
        item,
        ["user", "permalink", "category_id", "warehouse_stock_mapping"],
        this.props.items
      )
    );
    this.setState({
      csvExportData: exportItems,
      loading: false
    });
    return true;
  }

  render() {
    const {
      classes,
      searchTerm,
      handleClearFilters,
      lowStockClick,
      availableClick,
      archivedClick,
      statusClick,
      permittedToAccess
    } = this.props;
    return (
      <div className={classes.root}>
        <Toolbar>
          <CSVLink
            className={classes.toolbarButton}
            data={this.state.csvExportData}
            title={`Export current ${this.props.items.length} records`}
          >
            <Button
              className={classes.exportButton}
              color="inherit"
              aria-label="Export SKUs"
              disabled={!permittedToAccess("products:csvExport")}
              onClick={this.generateCSVExport}
              startIcon={<OpenInNewIcon />}
            >
              Export
            </Button>
          </CSVLink>
        </Toolbar>

        <div className={classes.flexContainer}>
          <SpaceSearch
            searchTerm={searchTerm}
            onChange={this.props.handleSearchInput}
            swapGrid={this.props.swapGrid}
            isGrid={this.props.grid}
            classname={classes.grow}
            fetchSearchResult={this.props.fetchSearchResult}
          />

          <div className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              onClick={(event) => this.setState({classicView: false})}
              variant="contained"
              color="primary"
              component={Link}
              aria-label="Add New Item"
              to={`/user/${UID}/space/products/add`}
              disabled={!permittedToAccess("products:create")}
            >
              Add Product
            </Button>

            <Button
              className={classes.button}
              onClick={this.toggleDrawer("right", true)}
              variant="contained"
            >
              Show Filters
            </Button>
          </div>
          <BasicDrawer
            open={this.state.filterPanelOpen}
            onClose={this.toggleDrawer("right", false)}
          >
            <SpaceFilterDrawerContents
              handleClearFilters={handleClearFilters}
              lowStockClick={lowStockClick}
              availableClick={availableClick}
              archivedClick={archivedClick}
              statusClick={statusClick}
            />
          </BasicDrawer>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {},
  exportButton: {
    marginRight: "2px",
    display: "flex",
  },
  toolbarButton: {
    textDecoration: "none",
    color: "#263238",
    marginLeft: "5px"
  },
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  button: {
    minWidth: "150px",
    margin: 5,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

export default withStyles(styles)(SpaceMenuBar);
