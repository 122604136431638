import keepspaceApi from 'services/keepspaceApi';

export const FETCH_CUSTOMERS_BEGIN = 'FETCH_CUSTOMERS_BEGIN';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE'


export const CREATE_CUSTOMER_BEGIN = 'CREATE_CUSTOMER_BEGIN';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'

export const DELETE_CUSTOMER_BEGIN = 'DELETE_CUSTOMER_BEGIN'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE'

export const fetchCustomersBegin = () => ({
    type: FETCH_CUSTOMERS_BEGIN
});

export const fetchCustomersSuccess = customers => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: { customers }
});

export const fetchCustomersFailure = error => ({
    type: FETCH_CUSTOMERS_FAILURE,
    payload: { error }
});



export const createCustomerBegin = () => ({
    type: CREATE_CUSTOMER_BEGIN
});

export const createCustomerSuccess = customer => ({
    type: CREATE_CUSTOMER_SUCCESS,
    payload: { customer }
});

export const createCustomerFailure = error => ({
    type: CREATE_CUSTOMER_FAILURE,
    payload: { error }
});

export const deleteCustomerBegin = () => ({
    type: DELETE_CUSTOMER_BEGIN,
});

export const deleteCustomerSuccess = (id) => ({
    type: DELETE_CUSTOMER_SUCCESS,
    payload: { id }
});

export const deleteCustomerFailure = (error) => ({
    type: DELETE_CUSTOMER_FAILURE,
    payload: { error }
});




export function fetchCustomers() {
    return dispatch => {
        dispatch(fetchCustomersBegin());
        return keepspaceApi.getCustomers()
            .then(json => dispatch(fetchCustomersSuccess(json)))
            .catch(error => dispatch(fetchCustomersFailure(error)))
    };
}



export function createCustomer(formData) {
    return dispatch => {
        dispatch(createCustomerBegin());
        return keepspaceApi.createCustomer(formData)
            .then(json => {
              dispatch(createCustomerSuccess(json))
              return json
            })
            .catch(error => {
              dispatch(createCustomerFailure(error))
            })
    };
}

export function deleteCustomer(id) {
    return dispatch => {
        dispatch(deleteCustomerBegin());
        return keepspaceApi.deleteCustomer(id)
            .then(() => {
              dispatch(deleteCustomerSuccess(id))
              return id
            })
            .catch(error => {
              dispatch(deleteCustomerFailure(error))
            })
    }
}

