import keepspaceApi from 'services/keepspaceApi';

export const FETCH_SUPPLIERS_BEGIN = 'FETCH_SUPPLIERS_BEGIN';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE'

export const CREATE_SUPPLIER_BEGIN = 'CREATE_SUPPLIER_BEGIN';
export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_FAILURE = 'CREATE_SUPPLIER_FAILURE'

export const DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS'
export const DELETE_SUPPLIER_FAILURE = 'DELETE_SUPPLIER_FAILURE'

export function fetchSuppliers() {
    return dispatch => {
        dispatch(fetchSuppliersBegin());
        return keepspaceApi.getSuppliers()
            .then(json => {
                dispatch(fetchSuppliersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchSuppliersFailure(error))
            });
    };
}

export function createSupplier(formData) {
    return dispatch => {
        dispatch(createSupplierBegin());
        let res = keepspaceApi.createSupplier(formData)
            .then(json => {
                dispatch(createSupplierSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createSupplierFailure(error))
            });
        return res
    }
}

export function deleteSupplier(id) {
 return dispatch => {
        return keepspaceApi.deleteSupplier(id)
            .then(json => {
                dispatch(deleteSupplierSuccess(id));
                return id;
            })
            .catch(error => {
                dispatch(deleteSupplierFailure(error))
            });
    }
}

export const fetchSuppliersBegin = () => ({
    type: FETCH_SUPPLIERS_BEGIN
});

export const fetchSuppliersSuccess = json => ({
    type: FETCH_SUPPLIERS_SUCCESS,
    data: json
});

export const fetchSuppliersFailure = error => ({
    type: FETCH_SUPPLIERS_FAILURE,
    payload: { error }
});

export const createSupplierBegin = () => ({
    type: CREATE_SUPPLIER_BEGIN
});

export const createSupplierSuccess = supplier => ({
    type: CREATE_SUPPLIER_SUCCESS,
    payload: { supplier }
});

export const createSupplierFailure = error => ({
    type: CREATE_SUPPLIER_FAILURE,
    payload: { error }
});


export const deleteSupplierSuccess = id => ({
    type: DELETE_SUPPLIER_SUCCESS,
    payload: { id }
})

export const deleteSupplierFailure = error => ({
    type: DELETE_SUPPLIER_FAILURE,
    payload: { error }
});
