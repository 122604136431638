/**
 *
 * @param v4RedirectPath
 * @param redirect
 * @returns {string}
 */
const getV4RedirectionLink = (v4RedirectPath = '/dashboard', redirect = false) => {
  const at = localStorage.getItem('access-token');
  const client = localStorage.getItem('client');
  const orgId = localStorage.getItem('organisation-uid');
  const uid = localStorage.getItem('uid');
  let host = process.env.REACT_APP_SASSY_URL;
  // host = 'http://localhost:3001';
  const redirectUrl = `${host}/auth/magic_sign_in?access-token=${at}&client=${client}&uid=${uid}&organisation-uid=${orgId}&redirectUrl=${v4RedirectPath}`;

  if (redirect) {
    window.open(redirectUrl, '_blank');
  }

  return redirectUrl;
};

export default getV4RedirectionLink;
