import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,

} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Img from 'react-image';
import getV4RedirectionLink from '../../../../../utils/magicLoginV4';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 16,
    textTransform: 'capitalize',
    flexGrow: 1
  },
  content: {
    padding: '0px 40px'
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  itemConatiner: {
    display: 'flex',
  },
  item: {
    padding: 40,
    width: '50%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  imageFrame: {
    height: 100,
    width: 100,
    marginLeft: 30,
    borderRadius: '50%',
    border: '1px solid white',
    // background: "linear-gradient(to right, #45B5F2 0%, #249ddd 100%)",
  },
  img: {
    borderRadius: '50%',
    height: 100,
    width: 100,
    objectFit: 'contain'
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer'
  }
}));

const OrderCard = props => {
  const {headRows, title, order, handleEditOpen, edit, items} = props;
  const classes = useStyles();

  const handleClick = (skuUid) => {
    getV4RedirectionLink(`/space/products/${skuUid}/summary`, true);
  }

  let editAction
  if(edit) {
    editAction =
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
      </CardActions>
  }

  if(!items) return null

  return (
    <Card className={classes.root} >
      <CardHeader title={title} />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.itemConatiner}>
          {headRows.map(row =>
            <div align='center' key={row.name} className={classes.item}>
              <Typography variant="overline">
                {row.label}
              </Typography>
              <Typography variant='h4'>
                {order[row.name] || '-'}
              </Typography>
            </div>
          )}
        </div>
        <Divider />
        <Table>
          <TableBody>
            {items.map(item =>
              <TableRow key={item.sku.id} className={classes.link} onClick={() => handleClick(item?.sku?.uid)}>
                <TableCell style={{width: '30%'}}>
                  <div className={classes.imageFrame}>
                    <Img
                      className={classes.img}
                      src={item.sku.image ? item.sku.image.url : null}
                      loader={<CircularProgress className={classes.img} size={100} variant={'indeterminate'} />}
                    />

                  </div>
                </TableCell>
                <TableCell>
                  <Typography>{item.sku.sku}</Typography>
                  <Typography variant='body2'>{item.sku.title}</Typography>
                </TableCell>
                <TableCell align='center' style={{minWidth: '20%'}}>
                  {item.quantity_ordered || '-'}
                </TableCell>
                <TableCell align='center' style={{minWidth: '20%'}}>
                  {item.sku.quantity_in_storage || 0} in storage
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>

      {editAction}

    </Card>
  );
};

OrderCard.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderCard;
