import React, {PureComponent} from 'react'
import {Link} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';
import {Grid, CardActionArea, Card, CardContent, Typography, Fade} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import ProductImage from 'components/Image/ProductImage';

import LazyLoad from 'react-lazyload';


class SpaceGridItem extends PureComponent {


  render() {
    const {classes} = this.props;
    const {item} = this.props;

    return (
      <Fade in>

        <Grid item xs={12} sm={12} className={classes.gridItem}>

          <Card className={classes.root} raised={true}>
            <CardActionArea>
              <a href={this.props.link} className={classes.raisedImageBlock} style={{borderColor: this.props.color}}   target="_blank"
                 rel="noopener noreferrer">
                <LazyLoad>
                  <ProductImage
                    src={this.props.item.image ? this.props.item.image.thumb.url || this.props.item.image.url : null}
                    image={this.props.item.image}

                    archived={this.props.item.archived}
                    className={classes.productImg}
                  />
                </LazyLoad>
              </a>
              <CardContent>
                <a href={this.props.link}   target="_blank"
                   rel="noopener noreferrer">
                  <Typography gutterBottom variant="h5" component="h2">
                    {this.props.item.archived ? "ARCHIVED" : ""}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="h2">
                    {this.props.item.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Category: {this.props.item.category || 'N/A'}
                  </Typography>
                  <Divider className={classes.divider}/>

                  {
                    !!this.props.item?.warehouse_stock_mapping && this.props.item?.warehouse_stock_mapping?.map(row => (
                      <Typography>Stock - {row.warehouse_name}: {row.quantity_stored || 0}</Typography>
                    ))
                  }

                  {
                    !item?.warehouse_stock_mapping && <Typography>Stock: 0</Typography>
                  }

                  {this.props.children}
                </a>
              </CardContent>
            </CardActionArea>
          </Card>

        </Grid>
      </Fade>
    )
  }
}


const styles = theme => ({
  gridItem: {
    padding: '20px',
    justifyContent: 'center',
    //maxWidth: '100%',
    maxWidth: '374px',
    position: 'relative'
  },
  contentBlock: {
    border: '5px solid #fff',
    borderTop: 'none',
  },
  productImg: {},
  raisedImageBlock: {
    display: 'inline-block',
    position: 'relative',  // Needed to make element appear "above" the card, otherwise it ducks below
    zIndex: 2,
    border: '5px solid #fff',
    borderBottom: 'none',
    width: '100%',
    height: 250,

  },
  title: {
    color: '#ebebeb',
    fontWeight: 600,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    position: 'relative',
  },
  card: {
    zIndex: 1,
    minHeight: '350px',

    marginTop: '-228px', // Must link
    paddingTop: '228px', // These two together
  },
  cardBody: {
    paddingLeft: '4px',
  },
  rowBrandAndCategory: {
    display: 'flex',
    flexWrap: 'no-wrap',
  },
  divider: {
    margin: '8px 0px'
  },
  flexGrow: {
    flexGrow: '1'
  }
});


export default withStyles(styles)(SpaceGridItem);
