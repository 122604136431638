import { withStyles } from '@material-ui/core';

import LinearProgress from '@material-ui/core/LinearProgress';

import { fetchCustomers } from 'actions/customerActions';
import checkPermission from 'components/checkPermission';

import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import CustomersAdd from 'views/user/Customers/CustomersAdd';

import CustomersIndex from 'views/user/Customers/CustomersIndex';
import getV4RedirectionLink from '../../../utils/magicLoginV4';
import RedirectToV4 from '../RedirectToV4';

class Customers extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      countries: props.countries || [],
    };
    this.LinearIndeterminate = this.LinearIndeterminate.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    if (!this.props.loading) {
      if (this.props.customers.length === 0) {
        this.props.dispatch(fetchCustomers());
      }
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  LinearIndeterminate(props) {
    //const { classes } = props;
    return (
      <div>
        <LinearProgress/>
        <br/>
        <LinearProgress color='secondary'/>
      </div>
    );
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page);
  };

  render() {
    return (
      <div>
        <SuccessSnackbar
          open={this.props.error !== null}
          error={this.props.error ? this.props.error.response.statusText : null}
        />
        {this.props.loading ? (
          <this.LinearIndeterminate/>
        ) : (
          <>
            <Route path='/user/:uid/manage/customers/' exact
                   render={(routeProps) => <CustomersIndex customers={this.props.customers} {...routeProps}
                                                           permittedToAccess={this.permittedToAccess}/>}/>

            <Route path='/user/:uid/manage/customers/:id(\d+)/' exact
                   render={() => <RedirectToV4 to={getV4RedirectionLink('/manage/customers')}/>}/>
            <Route path='/user/:uid/manage/customers/:id(\d+)/:tab' exact
                   render={() => <RedirectToV4 to={getV4RedirectionLink('/manage/customers')}/>}/>

            <Route path='/user/:uid/manage/customers/add' exact render={(routeProps) => <CustomersAdd {...routeProps}
                                                                                                      countries={this.state.countries} {...this.props}
                                                                                                      permittedToAccess={this.permittedToAccess}/>}/>

          </>
        )
        }
      </div>
    );
  }
}

const styles = theme => ({});


const mapStateToProps = (state, ownProps) => ({
  customers: state.customersReducer.customers,
  loading: state.customersReducer.loading,
  error: state.customersReducer.error,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  countries: state.adminAuthReducer.countries,
  ...ownProps,
});

export default connect(mapStateToProps)(withStyles(styles)(Customers));
