import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography
} from '@material-ui/core';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';



const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    borderTop: '1px #e3e2e2 solid',
    opactity: '0.7',
    whiteSpace: 'pre-line'
  },
  title: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    flexGrow: 1,
    height: '100%'
  },
  padding: {
    padding: theme.spacing(2),
  }
}));

const Terminology = props => {
  const {error, } = props
  const [collapsed, setCollapsed] = useState(true)
  const [edit, setEdit] = useState(false)
  const [terminology, setTerminology] = useState({title: props.terminology.title , details: props.terminology.details, id: props.terminology.id})
  const classes = useStyles();
  const [valid, setValid] = useState('')
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleClick = () => {
    setCollapsed(!collapsed)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


  let header =
        <div className={classes.header}>
            <div className={classes.title}  onClick={handleClick}>
              {terminology.title}
            </div>
        </div>



  let detailsDiv

  if (!collapsed && !edit) {
    detailsDiv =
        <Typography className={classes.description}>
          {terminology.details}
        </Typography>
  }

  return (
    <Card className={classes.card}>
        {header}
        {detailsDiv}
        <SuccessSnackbar
          onClose={handleSnackbarClose}
          open={openSnackbar}
          messageText={error ? null: "Changes saved!"}
          error={error? error.message : null}
        />
    </Card>
  );
};


export default Terminology;
