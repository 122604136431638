import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { fetchCountries } from 'actions/otherActions';
import { removeUserDetails, updateTerms } from 'actions/userActions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { signOutUser } from 'redux-token-auth-config';
import * as Storage from 'utils/storage';
import useRouter from 'utils/useRouter';
import TermsAndConditions from 'views/user/TermsAndConditions';
import DepricationAlert from '../../views/user/DepricationAlert';
import { NavBar, TopBar } from './components';


const useStyles = makeStyles((theme) => ({
  root: {
    //height: '100vh',
    //width: '100%',
    // display: 'flex',
    // flexDirection: 'column',
    //overflow: 'hidden'
    background: '#f4f6f8',
  },

  link: {
    color: '#3d7bf6',
  },

  topBar: {
    zIndex: 10,
    position: 'fixed',
    width: '100vw',

  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    //overflow: 'scroll',
    height: '100%',
    // paddingBottom: 64
    marginTop: 64,
    [theme.breakpoints.down('sm')]: {
      marginTop: 55,
    },
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto',
    height: '100%',
    position: 'fixed',
    background: '#fff',
  },
  main: {
    width: '100%',
    margin: '0 3wh',
    //left: '0px',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 250px)',
      position: 'absolute',
      left: '250px',
      margin: '0',
    },
  },
}));

const UserLayout = props => {
  const { route } = props;
  const dispatch = useDispatch();
  const { history } = useRouter();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const currentUser = useSelector(state => state.reduxTokenAuth);
  const [isUserTncVerified, setIsUserTncVerified] = useState(true);

  const handleLogout = async () => {
    try {
      await dispatch(signOutUser());
    } catch (e) {
      // do nothing
    } finally {
      removeUserDetails();
    }


  };

  const acceptTerms = () => {
    if (!isUserTncVerified) {
      dispatch(updateTerms()).then(() => {
        window.location.reload();
      }).catch(() => window.location.reload());

    }
  };
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const getCountries = async () => {
    const token = Storage.getItem('access-token');
    if (token) {
      await dispatch(fetchCountries());
    }
  };
  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {

    if (currentUser.currentUser.attributes.hasOwnProperty('termsOfService') &&
      currentUser.currentUser?.attributes?.termsOfService !== isUserTncVerified &&
      currentUser?.currentUser?.attributes?.termsOfService !== null) {
      setIsUserTncVerified(currentUser.currentUser.attributes.termsOfService);
    }
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />

      <div className={classes.container}>

        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          onMobileOpen={handleNavBarMobileOpen}
          openMobile={openNavBarMobile}
        />

        <main className={classes.main}>
        <DepricationAlert />
          {/* <Suspense fallback={<LinearProgress />}> */}
          {renderRoutes(route.routes)}
          {/* </Suspense> */}
        </main>

        <Dialog
          open={!isUserTncVerified}
          disableBackdropClick={true}
          scroll={'paper'}
          fullWidth={true}
          maxWidth='lg'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'
        >

          <DialogContent dividers={true}>
            <DialogContentText
              id='scroll-dialog-description'
            >
              <TermsAndConditions/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogout} color='primary'>
              Cancel
            </Button>
            <Button onClick={acceptTerms} color='primary'>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

      </div>

    </div>
  );
};

UserLayout.defaultProps = {
  termsOfService: true,
};
UserLayout.propTypes = {
  route: PropTypes.object,
};

export default UserLayout;
