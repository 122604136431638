import { withStyles } from '@material-ui/core';
import { fetchTerminologies } from 'actions/terminologyActions';
import { UID } from 'AppConstants';
import checkPermission from 'components/checkPermission';
import Header from 'components/Header/Header';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import getV4RedirectionLink from '../../../utils/magicLoginV4';
import FeatureRemovalNotice from '../FeatureRemovalNotice';
import RedirectToV4 from '../RedirectToV4';
import Terminology from './components/Terminology';

class Terminologies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.loading) {
      if (this.props.terminologies.length === 0) {
        this.props.dispatch(fetchTerminologies());
      }
    }
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FeatureRemovalNotice {...(getContent(classes))} showImg={false}/>
        <Header title='Orders' subtitle='Terminologies'/>

          <>
            {this.props.terminologies.map((terminology) => (
              <Terminology
                key={terminology.id}
                terminology={terminology}
                error={this.props.error}
                permittedToAccess={this.permittedToAccess}
              />
            ))}
          </>

        <Route
          path={`/user/${UID}/terminologies/add`}
          exact
          render={() => (
           <RedirectToV4 to={getV4RedirectionLink('/space/bundle')}/>
          )}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none !important',
    margin: '0 6px',
    color: 'blue',
  },
  heading: {
    marginBottom: '10px',
    fontWeight: '600',
    display: 'flex',
    fontSize: '10px',
    alignItems: 'center', justifyContent: 'center',
    gap: '10px',
    textAlign: 'center',
  },
  text: {
    fontSize: '16px',
  },
});

const mapPropsToState = (state, ownProps) => ({
  ...ownProps,
  terminologies: state.terminologiesReducer.terminologies,
  loading: state.terminologiesReducer.loading,
  error: state.terminologiesReducer.error,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
});

export default connect(mapPropsToState)(withStyles(styles)(Terminologies));

const getContent = (classes) => ({
  description: <p className={classes.text}>
    We have discontinued support for Terminologies. You are advised to use
    <a target={'_blank'} rel='noreferrer' className={classes.link} href={getV4RedirectionLink('/space/bundles')}>
      BUNDLE INSTRUCTIONS on platform v4.2.3
    </a>
    to address specific product requirements. For shipping-related matters, please refer to the available shipping
    methods, allowing you to choose the appropriate option for each order.
  </p>,
  title: 'Important Update',
});
