import {
    FETCH_TERMINOLOGIES_BEGIN,
    FETCH_TERMINOLOGIES_SUCCESS,
    FETCH_TERMINOLOGIES_FAILURE,
} from 'actions/terminologyActions';


const initialState = {
    terminologies: [],
    loading: false,
    error: null,
}

export default function usersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_TERMINOLOGIES_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_TERMINOLOGIES_SUCCESS:
            return  {
                ...state,
                loading: false,
                terminologies: action.payload.terminologies
            };
        case FETCH_TERMINOLOGIES_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        default:
            return state
    }
}
