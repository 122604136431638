import {
  Avatar,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCustomer } from '../../actions/customerActions';
import getV4RedirectionLink from '../../utils/magicLoginV4';
import ConfirmAlert from '../Help/ConfirmAlert';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    padding: '20px',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
});

const columns =
  [
    { label: 'Name', name: 'name' },
    { label: 'Company', name: 'company_name' },
    { label: 'Country', name: 'country' },
    { label: 'Type', name: 'customer_type' },
    { label: 'Total sold', name: 'total_sold', nested: true, prefix: '$ ' },
    { label: 'Orders', name: 'orders', nested: true },
    { label: 'Actions', name: 'actions' },
  ];

export function CustomersTable(props) {

  const { classes, customers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAlert, setOpenAlert] = useState(0);

  const handleChangePage = (event, page) => {
    setPage(page);
  };
  const dispatch = useDispatch();

  const handleAlertClose = () => {
    setOpenAlert(0);
  };
  const handleDelete = () => {
    dispatch(deleteCustomer(openAlert))
      .then(result => {
        if (result) {
        }
      });
    handleAlertClose();

  };

  const cellValue = (customer, column) => {
    let prefix = column.prefix || '';
    let value = column.nested ? customer.details[column.name] : customer[column.name];
    return value ? prefix + value : '-';
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value === 'All' ? customers.length : event.target.value);
    setPage(0);
  };
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell align={index===6? 'center' : 'left'}  key={column.name}
                         style={{ paddingLeft: column.name === 'name' ? '66px' : '' }}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((customer) => (
            <TableRow
              key={customer.id}
              hover
            >
              <TableCell>
                <div className={classes.nameCell}>
                  <Avatar
                    className={classes.avatar}
                    src={customer.avatar || `https://robohash.org/${customer.id}`}
                  >
                  </Avatar>
                  <div>
                    {customer.name}
                    <div>{customer.email}</div>
                  </div>
                </div>
              </TableCell>
              {columns.slice(1, columns.length - 1).map(column => (
                <TableCell key={column.name}>{cellValue(customer, column)}</TableCell>
              ))}
              <TableCell style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '7px',
              }}>
                <a href={getV4RedirectionLink(`/manage/customers/${customer?.uid}/summary`)} target='_blank'
                   rel='noopener noreferrer'>
                  View
                </a>
                <Button onClick={() => setOpenAlert(customer?.id)} style={{ cursor: 'pointer' }} variant={'text'}><span
                  style={{ textTransform: 'capitalize', color: 'red', cursor: 'pointer' }}>Delete</span> </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={customers.length}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 'All']}
      />

      <ConfirmAlert
        open={openAlert}
        description='Do you really want to delete this customer?'
        onSubmit={handleDelete}
        onClose={handleAlertClose}
      />
    </Paper>
  );
}

CustomersTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default memo(withStyles(styles)(CustomersTable));
