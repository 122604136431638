/**
 * Sanitizes the provided HTML by removing potentially dangerous tags like <script>, <iframe>, <object>, and <embed>.
 *
 * @param {string} html - The HTML string to be sanitized.
 * @param {string} tagName - The tag name of the container element to wrap the HTML content (e.g., 'div', 'span').
 *
 * @returns {string} - The sanitized HTML, with dangerous tags removed.
 */
export function sanitizeHTML(html, tagName='p') {
  const tempDiv = document.createElement(tagName);
  tempDiv.innerHTML = html;

  // Remove <script> and other dangerous tags
  const scripts = tempDiv.querySelectorAll('script, iframe, object, embed');
  scripts.forEach(script => script.remove());

  // Return sanitized HTML
  return tempDiv.innerHTML;
}

