import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectToV4 = ({ to: url, target = '_self', redirectTo, routerProps }) => {

  React.useEffect(() => {
    // Open the external URL in a new tab
    window.open(url, target);
  }, [target, url]);

  const orgId = localStorage.getItem('organisation-uid');

  if (!!redirectTo) {
    const redirectPath = `/user/${orgId}/${redirectTo}`
    return <Redirect to={redirectPath}/>; // Render nothing while redirecting
  }

  return null;
};


export default RedirectToV4;
