import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import checkPermission from 'components/checkPermission';
import Header from 'components/Header/Header';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NotAuthorised from 'views/Error401';
import getV4RedirectionLink from '../../../utils/magicLoginV4';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 'auto',
    marginBottom: '100px',
    width: '90%',
  },
  results: {
    marginTop: theme.spacing(3),
  },
  date: {
    paddingTop: '50px',
  },
  header: {
    cursor: 'pointer',
    padding: '15px',
  },
  content: {
    borderRadius: '6px',
    opacity: '1',
    boxShadow: '0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08)',
    WebkitBackfaceVisibility: 'hidden',
    backfaceVisibility: 'hidden',
    willChange: 'opacity,left,top',
    transition: 'opacity .2s cubic-bezier(.36,0,1,1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginTop: '20px',
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20 px, auto',
  },
  error: {
    color: '#f44336',
    padding: '5px',
  },
  image: {
    width: '100%',
    opacity: '0.4',
  },
  padding20: {
    padding: '20px 0',
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
  },
}));


const RedirectInventoryReports = ({ orgId }) => {
  useEffect(() => {
    getV4RedirectionLink('/reports/inventory', true);
  });

  return <Redirect to={`/user/${orgId}/overview`}/>;
};


const InventoryReports = () => {
  const classes = useStyles();
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);
  const orgId = localStorage.getItem('organisation-uid');

  if (!checkPermission(currentOrganisation.currentOrganisationUser.role, 'analytics:view')) {
    return <NotAuthorised/>;
  }

  return (
    <Page
      className={classes.root}
      title='Inventory Report'
    >
      <Header title='Reports' subtitle='Inventory'/>
      <RedirectInventoryReports orgId={orgId}/>
    </Page>
  );
};

export default InventoryReports;
