import { apiPath, authHeaders } from 'AppConstants';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_TERMINOLOGIES_BEGIN = 'FETCH_TERMINOLOGIES_BEGIN';
export const FETCH_TERMINOLOGIES_SUCCESS = 'FETCH_TERMINOLOGIES_SUCCESS';
export const FETCH_TERMINOLOGIES_FAILURE = 'FETCH_TERMINOLOGIES_FAILURE'

export function fetchTerminologies() {
    return dispatch => {
        dispatch(fetchTerminologiesBegin());
        return fetch(
            (apiPath + '/terminologies/'),
            {
                method: 'GET',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchTerminologiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchTerminologiesFailure(error))
            });
    };
}

function handleErrors(response) {
    if (!response.ok) {
        apiErrorHandler(response)
        throw Error(response.statusText);
    }
    return response;
}

export const fetchTerminologiesBegin = () => ({
    type: FETCH_TERMINOLOGIES_BEGIN
});

export const fetchTerminologiesSuccess = terminologies => ({
    type: FETCH_TERMINOLOGIES_SUCCESS,
    payload: { terminologies }
});

export const fetchTerminologiesFailure = error => ({
    type: FETCH_TERMINOLOGIES_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

