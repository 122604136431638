import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { applyDateInterface } from 'Interfaces';

import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
  TablePagination
} from "@material-ui/core";
import getV4RedirectionLink from '../../utils/magicLoginV4';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    overflow: 'auto'
  },
  table: {
  },
  tableRow: {
    position: 'relative'  // This enables the :after pseudo element on the <Link> to expand to the size of the row
  },
  tableCell: {
    padding: '14px 40px 14px 40px',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    }
  },
});


export function BundleTable (props) {

    const { classes, bundles, columns } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, page) => {
      setPage(page);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(event.target.value === 'All' ? bundles.length : event.target.value);
      setPage(0)
    };


    return (
      <Paper className={classes.root}>
        <Typography
          color="textSecondary"
          variant="body2"
          className={classes.tableCell}
        >
          {bundles.length} Records found.
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <Tooltip key={column.label} title={column.tooltip} placement="top">
                  <TableCell className={classes.tableCell}> {column.label}</TableCell>
                </Tooltip>
              ))}
              <TableCell className={classes.tableCell}>Products</TableCell>
              <TableCell className={classes.tableCell} align='center'>Bundle Quantity</TableCell>
              <TableCell className={classes.tableCell} align='center'>Quantity Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bundles.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(row => (
              <TableRow
                key={row.id}
                style={{cursor:'pointer'}}
                hover
                className={classes.tableRow}
                onClick={()=>{window.open(getV4RedirectionLink(`/space/bundles/${row?.uid}/summary`), '_blank')}}
              >
                {columns.map(column => (
                    <TableCell className={classes.tableCell}>
                      {column.value === 'createdAt' ? applyDateInterface(row[column.value]) : row[column.value]}
                    </TableCell>
                ))}
                 <TableCell className={classes.tableCell}>
                      {
                        (row.components) ? row.components.map(comp =>
                          <div>{comp.item_sku}</div>
                        ):null
                      }
                  </TableCell>
                  <TableCell className={classes.tableCell} align='center'>
                      {(row.components) ? row.components.map(comp =>
                        <div> {comp.item_quantity}</div>
                      ):null}
                  </TableCell>
                  <TableCell className={classes.tableCell} align='center'>
                      {(row.components) ? row.components.map(comp =>
                        <div> {comp.quantity_available}</div>
                      ):null}
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={bundles.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 'All']}
        />
      </Paper>
    );

}

BundleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BundleTable);
