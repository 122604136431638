import { Box, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles(() => ({
  depricationAlert: {
    fontSize: '18px',
    boxShadow: '0px 0px 10px #0000001f',
    padding: '20px 40px',
    width: '70%',
    margin: '50px auto',
    borderRadius: '18px',
    lineHeight: '28px !important',
    background: '#FFF !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  imgBox: {
    width: '450px',
    margin: '0 auto',
    '>img': {
      width: '100%',
    },
  },

  heading: {
    marginBottom: '20px',
    fontWeight: '600',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center', justifyContent: 'center',
    gap: '10px',
    textAlign: 'center',
  },

  link: {
    textDecoration: 'none !important',
    margin: '0 6px',
    color: 'blue',
  },
}));


const FeatureRemovalNotice = ({ title, description, showImg = true }) => {

  const classes = useStyles();
  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f9f9f9',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: 2,
        maxWidth: 600,
        margin: '20px auto',
      }}
    >

      <Box className={classes.depricationAlert} severity={'info'}>
        <Typography className={classes.heading} variant={'h5'}> <InfoOutlined/>{title} </Typography>

        {
          showImg && <Box className={classes.imgBox}>
            <img src={'/moved.png'} alt={'moved'}/>
          </Box>
        }

        {description}

      </Box>

    </Box>
  );
};


export default FeatureRemovalNotice;
