import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper, Button,
} from '@material-ui/core';
import { deleteSupplier } from '../../actions/supplierActions';
import getV4RedirectionLink from '../../utils/magicLoginV4';
import ConfirmAlert from '../Help/ConfirmAlert';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      }
    }
  });

const Headers = ['Company', 'Display Name','Name', 'Country', 'Orders','Costs','Actions'];

export function SuppliersTable (props) {

  const { classes, suppliers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  const handleAlertClose = () => {
    setOpenAlert(0);
  };

  const handleDelete = () => {
    dispatch(deleteSupplier(openAlert))
    handleAlertClose()
  }

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value === 'All' ? suppliers.length : event.target.value);
    setPage(0)
  };

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
                {Headers.map((header, index) => (
                    <TableCell align={index===6?'center': 'left'} key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(supplier => (
              <TableRow
                key={supplier.id}
                hover
              >
                <TableCell>{supplier.name || "-"}</TableCell>
                <TableCell>{supplier.display_name || "-"}</TableCell>
                <TableCell> {supplier.contact_name || "-"} </TableCell>
                <TableCell>{supplier.country || "-"}</TableCell>
                <TableCell>{supplier.orders || "-"}</TableCell>
                <TableCell>$ {supplier.costs}</TableCell>
                <TableCell style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '7px',
                }}>
                  <a href={getV4RedirectionLink(`/manage/suppliers/${supplier?.uid}/summary`)} target='_blank'
                     rel='noopener noreferrer'>
                    View
                  </a>
                  <Button onClick={() => setOpenAlert(supplier?.id)} style={{ cursor: 'pointer' }} variant={'text'}><span
                    style={{ textTransform: 'capitalize', color: 'red', cursor: 'pointer' }}>Delete</span> </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={suppliers.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 'All']}
        />
        <ConfirmAlert
          open = {openAlert}
          description  = "Do you really want to delete this supplier?"
          onSubmit = {handleDelete}
          onClose = {handleAlertClose}
        />
      </Paper>
    );
}

SuppliersTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default memo(withStyles(styles)(SuppliersTable));
