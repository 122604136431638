import { makeStyles } from '@material-ui/styles';
import Can from 'components/Can';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NotAuthorised from 'views/Error401';
import getV4RedirectionLink from '../../../utils/magicLoginV4';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  paper: {
    minHeight: 400,
    padding: theme.spacing(3),
    overflow: 'auto',
  },
  table: {
    tableLayout: 'auto',
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      padding: '12px 4px',
    },
    padding: '14px 40px 14px 16px',
  },

}));

const RedirectInvoices = ({ orgId }) => {
  useEffect(() => {
    getV4RedirectionLink('/settings/billing?page=1&per_page=20', true);
  });

  return <Redirect to={`/user/${orgId}/overview`}/>;

};


const Invoices = () => {
  const classes = useStyles();
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);

  const orgId = localStorage.getItem('organisation-uid');

  return (
    <div className={classes.root}>
      <Can
        role={currentOrganisation.currentOrganisationUser.role}
        perform='invoices:view'
        yes={() => <RedirectInvoices orgId={orgId}/>}
        no={() => <NotAuthorised/>}
      />

    </div>

  );

};

export default Invoices;
