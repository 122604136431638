import adminAuthReducer from 'reducers/admin/adminAuthReducer';
import adminInvoiceReducer from 'reducers/admin/adminInvoiceReducer';
import adminOrdersReducer from 'reducers/admin/adminOrdersReducer.js';
import adminOrganisationsReducer from 'reducers/admin/adminOrganisationsReducer';
import adminOrgUsersReducer from 'reducers/admin/adminOrgUsersReducer';
import adminUsersReducer from 'reducers/admin/adminUsersReducer';
import batchesReducer from 'reducers/admin/batchesReducer';
import adminBundlesReducer from 'reducers/admin/bundlesReducer';
import dutiesReducer from 'reducers/admin/dutiesReducer';
import materialReducer from 'reducers/admin/materialReducer';
import orgIntegrationsReducer from 'reducers/admin/orgIntegrationsReducer';
import orgSuppliers from 'reducers/admin/orgSuppliersReducer';
import ratesReducer from 'reducers/admin/ratesReducer';
import shippersReducer from 'reducers/admin/shippersReducer';
import shippingReducer from 'reducers/admin/shippingReducer';
import skuReducer from 'reducers/admin/skuReducer';
import terminologyOrgReducer from 'reducers/admin/terminologiesReducer';
import trolliesReducer from 'reducers/admin/trolliesReducer';
import walletReducer from 'reducers/admin/walletReducer';
import warehouseReducer from 'reducers/admin/warehouseReducer';
import bundlesReducer from 'reducers/bundlesReducer';
import categoriesReducer from 'reducers/categoriesReducer';
import customersReducer from 'reducers/customersReducer';
import featureFlagReducer from 'reducers/featureFlagReducer';
import orderReducer from 'reducers/orderReducer/orderReducer';
import organisationsReducer from 'reducers/organisationsReducer';
import organisationUsersReducer from 'reducers/organisationUsersReducer';
import publicCommentsReducer from 'reducers/publicCommentsReducer';
import spaceReducer from 'reducers/spaceReducer';
import suppliersReducer from 'reducers/suppliersReducer';
import terminologiesReducer from 'reducers/terminologiesReducer';
import usersReducer from 'reducers/usersReducer';
import { combineReducers } from 'redux';
import { reduxTokenAuthReducer } from 'redux-token-auth';
// import { reducer as formReducer } from 'redux-form';

export default combineReducers({
  reduxTokenAuth: reduxTokenAuthReducer,
  spaceReducer: spaceReducer,
  orderReducer: orderReducer,
  customersReducer: customersReducer,
  suppliersReducer: suppliersReducer,
  featureFlagReducer: featureFlagReducer,
  categoriesReducer: categoriesReducer,
  publicCommentsReducer: publicCommentsReducer,
  usersReducer: usersReducer,
  terminologiesReducer: terminologiesReducer,
  bundlesReducer: bundlesReducer,
  organisationsReducer: organisationsReducer,
  organisationUsersReducer: organisationUsersReducer,

  adminAuthReducer: adminAuthReducer,
  skuReducer: skuReducer,
  ratesReducer: ratesReducer,
  batchesReducer: batchesReducer,
  materialReducer: materialReducer,
  orgSuppliers: orgSuppliers,
  adminOrdersReducer: adminOrdersReducer,
  adminBundlesReducer: adminBundlesReducer,
  adminUsersReducer: adminUsersReducer,
  shippersReducer: shippersReducer,
  shippingReducer: shippingReducer,
  trolliesReducer: trolliesReducer,
  adminOrganisationsReducer: adminOrganisationsReducer,
  adminOrgUsersReducer: adminOrgUsersReducer,
  orgIntegrationsReducer: orgIntegrationsReducer,
  walletReducer: walletReducer,
  dutiesReducer: dutiesReducer,
  warehouseReducer: warehouseReducer,
  terminologyOrgReducer: terminologyOrgReducer,
  adminInvoiceReducer: adminInvoiceReducer,
  // form: formReducer // ← redux-form
});
